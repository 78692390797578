










































































































































































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { quanxianMixin } from "@/mixins/quanxianMixin";
import {
  PostBookContent,
  DelConnect,
  GetBookMulu,
  createRenwu,
} from "@/request/storehouse";

import {
  GetBiaozhuyuan,
  GetCategory,
  GetRenwuOption,
  AddRenwu,
} from "@/request/mark";
import { GetZhuantis } from "@/request/schema";
import { PostYuanwen, GetMedcaseEntityList } from "@/request/check";
import { PostBookPrimarySearch } from "@/request/common";
import { GetIdArr } from "@/utils/base";
import MainHeader from "@/components/main-header/main-header.vue";
import Empty from "@/components/empty.vue";
import Tree from "./components/tree.vue";
import Glwj from "../components/yuanwen/glwj.vue";
import RenwuEdit from "./components/search-edit.vue";
import RenwuList from "./components//renwu-list.vue";
import Read from "./components/read/read.vue";
@Component({
  components: {
    MainHeader,
    Empty,
    Tree,
    RenwuEdit,
    RenwuList,
    Glwj,
    Read,
  },
})
export default class Name extends mixins(quanxianMixin) {
  private navs: any = {}; // 左边导航数据
  private current: any = {}; // 左边选中的导航
  private id: any = "";
  private ifShowBiaozhu: any = false;
  private ifBiaozhu: any = false;
  private biaozhuFilter: any = {
    专题类型: "",
    章节: [],
  };
  private renwus: any = [];
  private fenleis: any = [];
  private biaozhuyuans: any = [];
  private yians: any = [];
  private bookData: any = {};
  private showBiaozhu: any = false;
  private name: any = "";
  private data: any = { content: "" };
  private tijiaoData: any = {
    select: [],
    text: "",
  };
  private ifShowSaveConfirm: any = false;
  private ifShowTijiao: any = false;
  private ifShowRenwu: any = false;
  private toPath: any = "";
  private saveType: any = "";
  private zhenci: any = [
    "初诊",
    "二诊",
    "三诊",
    "四诊",
    "五诊",
    "六诊",
    "七诊",
    "八诊",
    "九诊",
    "十诊",
  ];
  private zhuantis: any = [];
  private zhangjies: any = [];
  private searchData: any = {}; // 上一页面传入的搜索词
  private loading: any = false; // 选择章节获取选择项时会用到
  private renwuData: any = {}; // 头部任务数据
  private ifShowRead: any = false;
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private readBook() {
    console.log("查看原文");
    this.ifShowRead = true;
  }
  private getShowText(item: any) {
    let str: any = "";
    let arr: any = [];
    if (item["书名"]) {
      str += item["书名"];
    }
    if (item["父章节列表"] && item["父章节列表"].length > 0) {
      str += item["父章节列表"].join("-");
    }
    if (item["章节"]) {
      str += "-" + item["章节"];
    }
    if (item["章节名"]) {
      str += "-" + item["章节名"];
    }
    arr = str.split(this.searchData["检索内容"]);
    return arr;
  }
  private changeNavData(e: any) {
    this.navs = e;
  }
  private getZhangjieText(item: any) {
    let str: any = "";
    if (item["书名"]) {
      str += item["书名"];
    }
    if (item["章节"]) {
      str += "-" + item["章节"];
    }
    return str;
  }
  private changeCData(e: any) {
    // 获取章节内容
    this.getData(e);
  }
  private get isElse() {
    const index = this.tijiaoData.select.indexOf("其它");
    if (index == -1) {
      return false;
    } else {
      return true;
    }
  }
  private getText() {
    let str: any = "";
    str += "高级搜索：" + this.searchData["检索内容"];
    let name: any = "";
    this.searchData["古籍名称"].forEach((ele: any, index: any) => {
      if (index > 0) {
        name += "、";
      }
      name += ele["书名"];
    });
    str += "，古籍名称：" + name;
    var key: any = ["朝代", "医家", "古籍分类"];
    key.forEach((element: any) => {
      str += "，" + element + "：";
      if (this.searchData[element] && this.searchData[element].length > 0) {
        this.searchData[element].forEach((ele: any, index: any) => {
          if (index > 0) {
            str += "、";
          }
          str += ele["实体名称"];
        });
      } else {
        str += "全部";
      }
    });
    return str;
  }
  private getZhuantis() {
    GetZhuantis(this).then((res: any) => {
      this.zhuantis = [];
      res.forEach((ele: any) => {
        this.zhuantis.push({
          name: ele,
          entity: "",
          options: [],
        });
      });
    });
  }
  private openRenwuList(item: any) {
    this.ifShowRenwu = true;
  }
  private typeChange() {
    setTimeout(() => {
      if (this.biaozhuFilter["专题类型"] == "诊次") {
        this.biaozhuFilter["切分方式"] = "自定义";
        this.$forceUpdate();
      }
    }, 200);
  }
  private refresh() {
    this.getData(this.current);
    this.getNav();
  }
  private addRenwu() {
    if (
      !this.biaozhuFilter["专题类型"] ||
      this.biaozhuFilter["专题类型"].length == 0
    ) {
      this.$message.warning("请选择专题类型");
      return;
    }
    // if (this.biaozhuFilter["专题类型"] == "诊次") {
    //   if (!this.biaozhuFilter["medcase_id"]) {
    //     this.$message.warning("请选择关联医案实体");
    //     return;
    //   }
    //   if (!this.biaozhuFilter["诊次"]) {
    //     this.$message.warning("请选择诊次");
    //     return;
    //   }
    // }
    if (!this.biaozhuFilter["切分方式"]) {
      this.$message.warning("请选择切分方式");
      return;
    }
    if (
      this.biaozhuFilter["切分方式"] != "自定义" &&
      !this.biaozhuFilter["任务名称"]
    ) {
      this.$message.warning("请输入任务名称");
      return;
    }
    if (
      this.biaozhuFilter["切分方式"] == "按章节" &&
      this.biaozhuFilter["章节"].length == 0
    ) {
      this.$message.warning("请选择章节");
      return;
    }
    if (this.biaozhuFilter["切分方式"] == "自定义") {
      this.$message.warning("请选择内容创建标注任务");
      this.ifBiaozhu = true;
      this.ifShowBiaozhu = false;
      return;
    }
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      // 专题类型: this.biaozhuFilter["专题类型"],
      专题类型列表: [],
      原文库: "古籍",
      book_id: this.id,
      切分方式: this.biaozhuFilter["切分方式"],
      任务名称: this.biaozhuFilter["任务名称"],
      分类: this.biaozhuFilter["分类"],
      标注者id: this.biaozhuFilter["标注员"],
      book_content_ids: this.biaozhuFilter["章节"],
      entity_id: this.biaozhuFilter["medcase_id"],
    };
    if (
      this.biaozhuFilter["专题类型"] &&
      this.biaozhuFilter["专题类型"].length > 0
    ) {
      this.biaozhuFilter["专题类型"].forEach((ele: any) => {
        params["专题类型列表"].push({
          专题类型: ele.name,
          entity_id: ele.entity,
        });
      });
    }
    createRenwu(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.$message.success("新增成功");
        this.ifShowBiaozhu = false;
        this.getData(this.current);
        this.getNav();
      })
      .catch(() => {
        loading.close();
      });
  }
  private openBiaozhu() {
    this.biaozhuFilter = {
      专题类型: "",
    };
    // this.getNav();
    this.ifShowBiaozhu = true;
  }
  private force() {
    this.$forceUpdate();
  }
  private preview(url: any) {
    window.open(url);
  }
  private delImg(item: any) {
    this.$confirm("确定删除么？", "删除", {
      customClass: "commonConfirm",
    }).then((res: any) => {
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const params: any = {
        data: {
          title_id: this.current._id,
          file_id: item.file_id,
        },
      };
      DelConnect(this, params, loading)
        .then((res: any) => {
          loading.close();
          this.$message.success("删除成功");
          this.getData(this.current);
        })
        .catch(() => {
          loading.close();
        });
    });
  }
  private remoteNameMethod(val: any) {
    this.biaozhuFilter["任务名称"] = val;
    this.biaozhuFilter.id = "";
    const params: any = {
      params: {
        search: val,
      },
    };
    GetRenwuOption(this, params).then((res: any) => {
      this.renwus = res;
    });
  }
  private remoteNavMethod(val: any) {
    this.loading = true;
    const params: any = {
      书名: val,
      检索内容: this.searchData["检索内容"],
      书籍列表: this.searchData["古籍名称"],
      current_page: 1,
      page_num: 300,
      朝代: GetIdArr(this.searchData["朝代"] || [], "_id"),
      作者: GetIdArr(this.searchData["作者"] || [], "_id"),
      古籍分类: GetIdArr(this.searchData["古籍分类"] || [], "id"),
    };
    PostBookPrimarySearch(this, params).then((data: any) => {
      this.zhangjies = data.contents;
      this.loading = false;
    });
  }
  private nameChange(e: any) {
    this.biaozhuFilter["任务名称"] = e.name;
    this.biaozhuFilter.id = e._id;
    this.biaozhuFilter["标注员"] = e.user_id;
    this.biaozhuFilter["分类"] = e.category;
    this.$forceUpdate();
  }
  private remoteFenleiMethod(e: any) {
    const params: any = {
      params: {
        search: e,
      },
    };
    GetCategory(this, params).then((res: any) => {
      this.fenleis = res;
    });
  }
  private remoteBiaozhuyuanMethod(e: any) {
    const params: any = {
      params: {
        search: e,
      },
    };
    GetBiaozhuyuan(this, params).then((res: any) => {
      this.biaozhuyuans = res;
    });
  }
  private removeZhuanti(e: any) {
    e.entity = "";
    e.options = [];
  }
  private remoteYianMethod(item: any, e: any) {
    const params: any = {
      params: {
        search: e,
        专题类型: item.name,
      },
    };
    // const params: any = {
    //   params: {
    //     search: e,
    //     专题类型:
    //       this.biaozhuFilter["专题类型"] == "诊次"
    //         ? "医案病历"
    //         : this.biaozhuFilter["专题类型"],
    //   },
    // };
    GetMedcaseEntityList(this, params).then((res: any) => {
      item.options = res;
    });
  }
  private remoteZhangjieMethod(e: any) {
    const params: any = {
      params: {
        search: e,
      },
    };
    GetBiaozhuyuan(this, params).then((res: any) => {
      this.biaozhuyuans = res;
    });
  }
  private edit() {
    this.$store.commit("updateIfCompose", true);
  }
  private input(e: any) {
    const index = this.tijiaoData.select.indexOf("其它");
    if (index === -1) {
      this.tijiaoData.select.push("其它");
    }
  }
  private saveTijiao() {
    this.saveType = "离开提交";
    this.tijiaoData = {
      select: [],
      text: "",
    };
    this.ifShowTijiao = true;
  }
  private tijiao() {
    if (this.tijiaoData.select.length == 0) {
      this.$message.warning("请先选择本次修改的内容再提交");
      return;
    }
    const index = this.tijiaoData.select.findIndex((v: any) => {
      return v === "其它";
    });
    let result: any = [];
    if (index === -1) {
      result = this.tijiaoData.select;
    } else {
      if (this.tijiaoData.text) {
        result = this.tijiaoData.select;
        result[index] = "其它，" + this.tijiaoData.text;
      } else {
        result = this.tijiaoData.select;
      }
    }
    const params: any = {
      title_id: this.data.title_id,
      status: "提交",
      text: result.join("、"),
      content: this.data.content,
      章节名: this.data["章节名"],
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    PostYuanwen(this, params)
      .then((res: any) => {
        loading.close();
        this.$message.success("提交成功！");
        this.ifShowTijiao = false;
        (this.$refs.nav as any).getNav();

        // if (this.saveType == "提交") {
        //   this.back();
        // } else {
        //   this.goDefault();
        // }
      })
      .catch(() => {
        loading.close();
      });
  }
  // 跳转目标页面
  private goDefault() {
    this.$store.commit("updateIfCompose", false);
    this.$router.push({
      path: this.toPath,
    });
  }
  private back() {
    this.$store.commit("updateIfCompose", false);
    this.$router.back();
  }
  /**
   * @description 离开页面前保存
   */
  private beforeRouteLeave(to: any, from: any, next: any) {
    if (this.ifCompose) {
      this.toPath = to.fullPath;
      this.ifShowSaveConfirm = true;
      next(false);
    } else {
      next(true);
    }
  }
  private getData(e?: any) {
    if (e) {
      this.current = e;
    }
    this.ifBiaozhu = false;
    if (!e._id) {
      this.data = {};
      return;
    }
    const params: any = {
      params: {
        title_id: this.current._id,
      },
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    PostBookContent(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.data = res;
      })
      .catch(() => {
        loading.close();
      });
  }
  private save() {
    const params: any = {
      title_id: this.data.title_id,
      status: "保存草稿",
      content: this.data.content,
      章节名: this.data["章节名"],
    };
    PostYuanwen(this, params).then((res: any) => {
      this.$message.success("保存草稿成功！");
      this.$store.commit("updateIfCompose", false);
      (this.$refs.nav as any).getNav();
      // this.getData(this.current);
    });
  }
  private getNav() {
    return new Promise((resolve, reject) => {
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const params: any = {
        检索内容: this.searchData["检索内容"],
        current_page: 1,
        page_num: 50,
        书籍列表: this.searchData["古籍名称"],
        朝代: GetIdArr(this.searchData["朝代"] || [], "_id"),
        作者: GetIdArr(this.searchData["作者"] || [], "_id"),
        古籍分类: GetIdArr(this.searchData["古籍分类"] || [], "id"),
      };
      PostBookPrimarySearch(this, params, loading).then((res: any) => {
        loading.close();
        // 展示头部任务数
        this.renwuData = res;
        resolve(res);
      });
    });
  }
  mounted() {
    if (this.$route.query.search) {
      this.searchData = JSON.parse(this.$route.query.search as any);
      this.getNav();
    }
    this.getZhuantis();
  }
}
