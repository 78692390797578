
























































































































































































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { quanxianMixin } from "@/mixins/quanxianMixin";
import {
  PostBookContent,
  DelConnect,
  GetBookMulu,
  createRenwu,
} from "@/request/storehouse";

import {
  GetBiaozhuyuan,
  GetCategory,
  GetRenwuOption,
  AddRenwu,
} from "@/request/mark";
import { GetZhuantis } from "@/request/schema";
import { PostYuanwen, GetMedcaseEntityList } from "@/request/check";
import MainHeader from "@/components/main-header/main-header.vue";
import Empty from "@/components/empty.vue";
import Tree from "./tree.vue";
import Glwj from "../../../components/yuanwen/glwj.vue";
import RenwuEdit from "../renwu-edit.vue";
import RenwuList from "../renwu-list.vue";
@Component({
  components: {
    MainHeader,
    Empty,
    Tree,
    RenwuEdit,
    RenwuList,
    Glwj,
  },
})
export default class Name extends mixins(quanxianMixin) {
  @Prop()
  private filter: any;
  @Prop()
  private book: any;
  @Prop()
  private ifShow: any;
  @Watch("ifShow", { immediate: true })
  private ifShowChange() {
    this.ifShowRead = this.ifShow;
    if (this.ifShow) {
      console.log("父组件数据");
      console.log(this.filter);
      console.log(this.book);
      this.id = this.filter.book_id;
      // (this.$refs.nav as any).getNav();
      this.getNav();
      this.getZhuantis();
    }
    console.log(this.filter);
  }
  private ifShowRead: any = false;
  private id: any = "";
  private ifShowBiaozhu: any = false;
  private ifBiaozhu: any = false;
  private biaozhuFilter: any = {
    专题类型: "",
  };
  private renwus: any = [];
  private fenleis: any = [];
  private biaozhuyuans: any = [];
  private yians: any = [];
  private bookData: any = {};
  private showBiaozhu: any = false;
  private name: any = "";
  private current: any = {};
  private data: any = { content: "" };
  private tijiaoData: any = {
    select: [],
    text: "",
  };
  private ifShowRenwu: any = false;
  private renwuTime: any = "";
  private zhenci: any = [
    "初诊",
    "二诊",
    "三诊",
    "四诊",
    "五诊",
    "六诊",
    "七诊",
    "八诊",
    "九诊",
    "十诊",
  ];
  private zhuantis: any = [];
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private get isElse() {
    const index = this.tijiaoData.select.indexOf("其它");
    if (index == -1) {
      return false;
    } else {
      return true;
    }
  }
  private cancel() {
    this.bookData = {};
    this.$emit("cancel");
  }
  private getZhuantis() {
    GetZhuantis(this).then((res: any) => {
      this.zhuantis = [];
      res.forEach((ele: any) => {
        this.zhuantis.push({
          name: ele,
          entity: "",
          options: [],
        });
      });
    });
  }
  private openRenwuList(item: any) {
    this.renwuTime = new Date().getTime();
    this.ifShowRenwu = true;
  }
  private typeChange() {
    setTimeout(() => {
      if (this.biaozhuFilter["专题类型"] == "诊次") {
        this.biaozhuFilter["切分方式"] = "自定义";
        this.$forceUpdate();
      }
    }, 200);
  }
  private refresh() {
    this.getData(this.current);
    this.getNav();
  }
  private addRenwu() {
    if (
      !this.biaozhuFilter["专题类型"] ||
      this.biaozhuFilter["专题类型"].length == 0
    ) {
      this.$message.warning("请选择专题类型");
      return;
    }
    // if (this.biaozhuFilter["专题类型"] == "诊次") {
    //   if (!this.biaozhuFilter["medcase_id"]) {
    //     this.$message.warning("请选择关联医案实体");
    //     return;
    //   }
    //   if (!this.biaozhuFilter["诊次"]) {
    //     this.$message.warning("请选择诊次");
    //     return;
    //   }
    // }
    if (!this.biaozhuFilter["切分方式"]) {
      this.$message.warning("请选择切分方式");
      return;
    }
    if (
      this.biaozhuFilter["切分方式"] != "自定义" &&
      !this.biaozhuFilter["任务名称"]
    ) {
      this.$message.warning("请输入任务名称");
      return;
    }
    if (
      this.biaozhuFilter["切分方式"] == "按章节" &&
      this.biaozhuFilter["章节"].length == 0
    ) {
      this.$message.warning("请选择章节");
      return;
    }
    if (this.biaozhuFilter["切分方式"] == "自定义") {
      this.$message.warning("请选择内容创建标注任务");
      this.ifBiaozhu = true;
      this.ifShowBiaozhu = false;
      return;
    }
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      // 专题类型: this.biaozhuFilter["专题类型"],
      专题类型列表: [],
      原文库: "古籍",
      book_id: this.id,
      切分方式: this.biaozhuFilter["切分方式"],
      任务名称: this.biaozhuFilter["任务名称"],
      分类: this.biaozhuFilter["分类"],
      标注者id: this.biaozhuFilter["标注员"],
      book_content_ids: this.biaozhuFilter["章节"],
      entity_id: this.biaozhuFilter["medcase_id"],
    };
    if (
      this.biaozhuFilter["专题类型"] &&
      this.biaozhuFilter["专题类型"].length > 0
    ) {
      this.biaozhuFilter["专题类型"].forEach((ele: any) => {
        params["专题类型列表"].push({
          专题类型: ele.name,
          entity_id: ele.entity,
        });
      });
    }
    createRenwu(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.$message.success("新增成功");
        this.ifShowBiaozhu = false;
        this.getData(this.current);
        this.getNav();
      })
      .catch(() => {
        loading.close();
      });
  }
  private openBiaozhu() {
    this.biaozhuFilter = {
      专题类型: "",
    };
    this.getNav();
    this.ifShowBiaozhu = true;
  }
  private force() {
    this.$forceUpdate();
  }
  private preview(url: any) {
    window.open(url);
  }
  private delImg(item: any) {
    this.$confirm("确定删除么？", "删除", {
      customClass: "commonConfirm",
    }).then((res: any) => {
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const params: any = {
        data: {
          title_id: this.current._id,
          file_id: item.file_id,
        },
      };
      DelConnect(this, params, loading)
        .then((res: any) => {
          loading.close();
          this.$message.success("删除成功");
          this.getData(this.current);
        })
        .catch(() => {
          loading.close();
        });
    });
  }
  private remoteNameMethod(val: any) {
    this.biaozhuFilter["任务名称"] = val;
    this.biaozhuFilter.id = "";
    const params: any = {
      params: {
        search: val,
      },
    };
    GetRenwuOption(this, params).then((res: any) => {
      this.renwus = res;
    });
  }
  private nameChange(e: any) {
    this.biaozhuFilter["任务名称"] = e.name;
    this.biaozhuFilter.id = e._id;
    this.biaozhuFilter["标注员"] = e.user_id;
    this.biaozhuFilter["分类"] = e.category;
    this.$forceUpdate();
  }
  private remoteFenleiMethod(e: any) {
    const params: any = {
      params: {
        search: e,
      },
    };
    GetCategory(this, params).then((res: any) => {
      this.fenleis = res;
    });
  }
  private remoteBiaozhuyuanMethod(e: any) {
    const params: any = {
      params: {
        search: e,
      },
    };
    GetBiaozhuyuan(this, params).then((res: any) => {
      this.biaozhuyuans = res;
    });
  }
  private removeZhuanti(e: any) {
    e.entity = "";
    e.options = [];
  }
  private remoteYianMethod(item: any, e: any) {
    const params: any = {
      params: {
        search: e,
        专题类型: item.name,
      },
    };
    // const params: any = {
    //   params: {
    //     search: e,
    //     专题类型:
    //       this.biaozhuFilter["专题类型"] == "诊次"
    //         ? "医案病历"
    //         : this.biaozhuFilter["专题类型"],
    //   },
    // };
    GetMedcaseEntityList(this, params).then((res: any) => {
      item.options = res;
    });
  }
  private remoteZhangjieMethod(e: any) {
    const params: any = {
      params: {
        search: e,
      },
    };
    GetBiaozhuyuan(this, params).then((res: any) => {
      this.biaozhuyuans = res;
    });
  }
  private getData(e: any) {
    this.current = e;
    this.ifBiaozhu = false
    if (!e._id) {
      this.data = {};
      return;
    }
    const params: any = {
      params: {
        title_id: this.current._id,
      },
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    PostBookContent(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.data = res;
        console.log(this.data);
      })
      .catch(() => {
        loading.close();
      });
  }
  private getNav() {
    return new Promise((resolve, reject) => {
      console.log("获取左边导航");
      console.log(this.id);
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const params: any = {
        params: {
          book_id: this.id,
          need_status: true,
        },
      };
      GetBookMulu(this, params, loading).then((res: any) => {
        loading.close();
        this.bookData = res;
        resolve(res);
      });
    });
  }
  mounted() {}
}
