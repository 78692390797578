

































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
@Component({})
export default class Name extends mixins(listMixin) {
  @Prop()
  private detail: any;
  @Watch("detail", { immediate: true })
  private detailChange() {
    this.data = JSON.parse(JSON.stringify(this.detail["任务"]));
  }
  private data: any = [];
}
