































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { AddDaohang } from "@/request/schema";
import {
  GetBookMulu,
  DropChapter,
  EditChapter,
  DelChapter,
} from "@/request/storehouse";
import SaveConfirm from "@/components/saveConfirm.vue";
@Component({
  components: {
    SaveConfirm,
  },
})
export default class Name extends Vue {
  @Prop()
  private page: any;
  @Prop()
  private current: any; // 搜索页当前选中的
  @Prop()
  private detail: any;
  @Prop()
  private data: any;
  @Watch("data", { immediate: true })
  private dataChange() {
    if (this.data.titles && this.data.titles.length > 0) {
      if (this.current && this.current.title_id) {
        this.data.titles.forEach((ele: any) => {
          if (ele._id == this.current.title_id) {
            this.currentNode = ele;
            this.init();
          }
        });
      } else if (!this.currentNode._id) {
        this.currentNode = this.data.titles[0];
        this.init();
      } else {
        this.init();
        return;
      }
    }
  }
  private id: any = "";
  private currentNode: any = {};
  private expandedNode: any = [];
  private defaultProps: any = {
    label: "章节名",
  };
  private addJiegouData: any = {};
  private modifyData: any = {};
  private ifShowAdd: any = false;
  private clickData: any = {}; // 点击的数据
  private ifShowSaveConfirm: any = false;
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private handleNodeClick(data: any) {
    if (this.ifCompose) {
      this.clickData = data;
      this.ifShowSaveConfirm = true;
    } else {
      this.currentNode = data;
      this.init();
    }
  }
  private init() {
    setTimeout(() => {
      if (this.$refs.tree) {
        (this.$refs.tree as any).setCurrentKey(this.currentNode);
        this.expandedNode = [this.currentNode["_id"]];
        this.data.titles.forEach((ele: any) => {
          if (ele._id == this.currentNode._id) {
            this.currentNode = ele;
          }
        });
        this.$emit("refresh", this.currentNode);
      }
    }, 100);
  }
}
